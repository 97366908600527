import { IStartUpSearchResponse } from "./search.model";
import { IdNameOptions } from "./startup.model";

export interface PartnersInformation {
  uuid: string;
  name: string;
  partnerType: string;
  website: string;
  shortDescription: string;
  longDescription: string;
  logo: string;
  tagline: string;
  tags: string;
  location: string;
  inviteCode: string;
  startups?: IStartUpSearchResponse[]
  applicationLink: string;
  isApproved: boolean
  user: any


  registeredCountryR: IdNameOptions;
  registeredStateR: IdNameOptions;
  registeredCityR: IdNameOptions;
  twitterUrl: string;
  linkedinUrl: string;
  facebookUrl: string,
  instagramUrl: string,
  youtubeUrl: string,

  partnerEvents: IPartnerEvent[]


  partnerIndustries :Array<{name:string}>
  partnerTechnologies :Array<{name:string}>
  partnerOtherIndustries :Array<string>
  partnerOtherTechnologies :Array<string>

}
export interface IPartnerEvent {
  uuid: string
  eventName: string
  shortDescription: string
  eventDate: string
  images: string[]
  eventType: EventType
}

export interface EventType {
  name: string
}



export interface PartnerProfileCompleteness {
  total: number;
  completed: number;
  percentage: number;
  canRequestApproval: boolean;
  canToggleStatus: boolean;
  isApprovalRequested: boolean;
  isProvidingFundings: boolean;
  forms: any;
  isRejected: boolean;
  isApproved: boolean
  approvalStatus: string
}

export enum PartnerType {
  INCUBATOR = 'incubator',
  ACCELERATOR = 'accelerator',
  ASSOCIATION_ORGANIZATION = 'association-organization',
}

export const PartnerTypeLabelMap = {
  [PartnerType.INCUBATOR]: 'Incubator',
  [PartnerType.ACCELERATOR]: 'Accelerator',
  [PartnerType.ASSOCIATION_ORGANIZATION]: 'Association organization',
}

